import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  ForwardedRef,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Box,
  TextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import {
  DataSheetGrid,
  createTextColumn,
  dateColumn,
  keyColumn,
  DataSheetGridRef,
  Column,
  DataSheetGridProps,
} from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import axios from 'axios';
import config from '../config';
import { selectColumn } from '../components/selectColumn';

// Återanvänd interfaces från gamla filen
interface ImportRow {
  articleNo: string;
  pluNo: string;
  attribute1Code?: string | null;
  attribute2Code?: string | null;
  attribute3Code?: string | null;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  ean13: string | null;
  articleName: string | null;
  supplierPrice: string | null;
  weight: string | null;
  price: string | null;
  priceOrig: string | null;
  brand: string | null;
  activationDate: Date | null;
  exactMatchExists?: boolean;
  existingPlu?: string;
  matchedAttributes?: string[];
  partialMatch?: boolean;
}

interface AttributeOption {
  code: string;
  number: string;
}

interface Attributes {
  attribute1: (AttributeOption | string)[];
  attribute2: string[];
  attribute3: string[];
  brands: string[];
}

const createEmptyRow = (): ImportRow => ({
  articleNo: '',
  pluNo: '',
  attribute1: null,
  attribute2: null,
  attribute3: null,
  ean13: '',
  articleName: '',
  supplierPrice: '',
  weight: '',
  price: '',
  priceOrig: '',
  brand: null,
  activationDate: new Date(),
});

const generateRowKey = ({
  rowData,
  rowIndex,
}: {
  rowData: ImportRow;
  rowIndex: number;
}) => {
  if (rowData.articleNo && rowData.pluNo) {
    return `${rowData.articleNo}-${rowData.pluNo}`;
  }
  return `row-${rowIndex}`;
};

// Lägg till interface för props
interface ProductImportEditProps {
  isReadOnly?: boolean;
}

// Lägg till efter ImportRow interface
interface ValidationError {
  row: number;
  errors: string[];
}

// Lägg till denna valideringsfunktion före ProductImportEdit komponenten
const validateRow = (
  row: Omit<ImportRow, 'activationDate'> & {
    activationDate: string | Date | null;
  },
  index: number,
): string[] => {
  const errors: string[] = [];

  if (row.ean13 && row.ean13.length > 13) {
    errors.push(`EAN13 får inte innehålla mer än 13 siffror`);
  }

  if (row.weight && !/^\d+$/.test(row.weight)) {
    errors.push(`Vikt får endast innehålla siffror`);
  }

  const priceRegex = /^\d+([.,]\d+)?$/;
  if (row.supplierPrice && !priceRegex.test(row.supplierPrice)) {
    errors.push(`Inpris får endast innehålla siffror med en punkt eller komma`);
  }

  if (row.price && !priceRegex.test(row.price)) {
    errors.push(`Pris får endast innehålla siffror med en punkt eller komma`);
  }

  if (row.priceOrig && !priceRegex.test(row.priceOrig)) {
    errors.push(
      `Ordinarie pris får endast innehålla siffror med en punkt eller komma`,
    );
  }

  return errors;
};

// Lägg till ny hjälpfunktion för att gruppera fel
const groupValidationErrors = (errors: ValidationError[]) => {
  const groupedErrors: Record<string, number[]> = {};

  errors.forEach(({ row, errors }) => {
    errors.forEach((error) => {
      if (!groupedErrors[error]) {
        groupedErrors[error] = [];
      }
      groupedErrors[error].push(row);
    });
  });

  return Object.entries(groupedErrors).map(([error, rows]) => ({
    message: error,
    rows: rows.sort((a, b) => a - b),
  }));
};

const ProductImportEdit: React.FC<ProductImportEditProps> = ({
  isReadOnly = false,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState<ImportRow[]>([
    createEmptyRow(),
    createEmptyRow(),
    createEmptyRow(),
  ]);
  const [importName, setImportName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [attributes, setAttributes] = useState<Attributes>({
    attribute1: [],
    attribute2: [],
    attribute3: [],
    brands: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [usedPluNumbers, setUsedPluNumbers] = useState<Set<string>>(new Set());
  const gridRef = useRef<DataSheetGridRef>(null);
  const [feedback, setFeedback] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({ open: false, message: '', severity: 'success' });
  const [previousRows, setPreviousRows] = useState<ImportRow[]>([]);
  const [validationDialog, setValidationDialog] = useState<{
    open: boolean;
    errors: ValidationError[];
  }>({
    open: false,
    errors: [],
  });
  const debouncePluUpdateRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('Ingen token hittades');
        setIsLoading(false);
        return;
      }

      try {
        // Hämta attribut och varumärken parallellt
        const [attributesResponse, brandsResponse] = await Promise.all([
          axios.get(`${config.API_URL}/product-imports/attributes`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          axios.get(`${config.API_URL}/product-imports/brands`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        setAttributes({
          ...attributesResponse.data,
          brands: brandsResponse.data.map((b: { name: string }) => b.name),
        });

        // Om vi har ett ID, hämta även existerande import
        if (id) {
          await fetchExistingImport();
        }
      } catch (error: any) {
        console.error('Fel vid hämtning av data:', error);
        setFeedback({
          open: true,
          message: 'Kunde inte hämta data',
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const fetchExistingImport = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${config.API_URL}/product-imports/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setImportName(response.data.importName);

      // Konvertera activationDate till Date-objekt för varje rad och mappa attributkoder
      const processedRows = response.data.rows.map((row: any) => ({
        ...row,
        activationDate: row.activationDate
          ? new Date(row.activationDate)
          : null,
        // Mappa attributkoderna till attributfälten
        attribute1: row.attribute1Code,
        attribute2: row.attribute2Code,
        attribute3: row.attribute3Code,
      }));

      setRows(processedRows);

      const usedPlus = new Set<string>(
        processedRows.map((row: ImportRow) => row.pluNo).filter(Boolean),
      );
      setUsedPluNumbers(usedPlus);
    } catch (error) {
      console.error('Fel vid hämtning av import:', error);
      setFeedback({
        open: true,
        message: 'Kunde inte hämta importen',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowsChange = async (
    newRows: ImportRow[],
    operations: { type: string; fromRowIndex: number; toRowIndex: number }[],
  ) => {
    const DEBOUNCE_TIME = 300;

    console.log('handleRowsChange triggered:', {
      newRows,
      operations,
    });

    const adjustedRows = [...newRows].map((row) => {
      if (!row.articleNo?.trim()) {
        return {
          ...row,
          articleNo: '',
          pluNo: '',
        };
      }
      return row;
    });

    while (
      adjustedRows.length <=
      adjustedRows.filter((row) => row.articleNo?.trim()).length
    ) {
      adjustedRows.push(createEmptyRow());
    }

    setRows(adjustedRows);

    if (debouncePluUpdateRef.current) {
      clearTimeout(debouncePluUpdateRef.current);
    }

    debouncePluUpdateRef.current = setTimeout(async () => {
      const rowsToProcess = adjustedRows
        .map((row, index) => ({ row, index }))
        .filter(({ row, index }) => {
          const prevRow = rows[index];
          const currentArticleNo = row.articleNo?.trim() || '';
          const prevArticleNo = prevRow?.articleNo?.trim() || '';

          const attribute1Changed = row.attribute1 !== prevRow?.attribute1;
          const attribute2Changed = row.attribute2 !== prevRow?.attribute2;
          const attribute3Changed = row.attribute3 !== prevRow?.attribute3;
          const articleNoChanged = currentArticleNo !== prevArticleNo;

          console.log('Checking row changes:', {
            rowIndex: index,
            currentArticleNo,
            prevArticleNo,
            attribute1: {
              current: row.attribute1,
              previous: prevRow?.attribute1,
              changed: attribute1Changed,
            },
            attribute2: {
              current: row.attribute2,
              previous: prevRow?.attribute2,
              changed: attribute2Changed,
            },
            attribute3: {
              current: row.attribute3,
              previous: prevRow?.attribute3,
              changed: attribute3Changed,
            },
            articleNoChanged,
          });

          return (
            currentArticleNo &&
            (articleNoChanged ||
              attribute1Changed ||
              attribute2Changed ||
              attribute3Changed ||
              !row.pluNo)
          );
        });

      if (rowsToProcess.length === 0) {
        console.log('No rows to process');
        return;
      }

      console.log('Rows to process:', rowsToProcess);

      const currentPluNumbers = new Set(
        adjustedRows.map((r) => r.pluNo).filter(Boolean),
      );

      for (const { row, index } of rowsToProcess) {
        try {
          const token = localStorage.getItem('token');

          console.log('Making API request for row:', {
            rowIndex: index,
            articleNo: row.articleNo.trim(),
            attributes: {
              attribute1: row.attribute1,
              attribute2: row.attribute2,
              attribute3: row.attribute3,
            },
            existingPluNumbers: Array.from(currentPluNumbers),
            importId: id,
          });

          const { data } = await axios.get(
            `${config.API_URL}/product-imports/next-plu/${row.articleNo.trim()}`,
            {
              params: {
                existingPluNumbers: Array.from(currentPluNumbers),
                attribute1Code: row.attribute1 || undefined,
                attribute2Code: row.attribute2 || undefined,
                attribute3Code: row.attribute3 || undefined,
                importId: id,
                checkPartialMatch: true,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          console.log('Backend response:', {
            rowIndex: index,
            articleNo: row.articleNo,
            response: data,
            matchDetails: {
              exactMatchExists: data.exactMatchExists,
              existingPlu: data.existingPlu,
              matchedAttributes: data.matchedAttributes,
              partialMatch: data.partialMatch,
            },
          });

          let nextPlu = data.nextPlu;

          if (!nextPlu) {
            const existingPluNumbers = Array.from(currentPluNumbers)
              .map((plu) => parseInt(plu))
              .filter((num) => !isNaN(num));

            const maxPlu = Math.max(0, ...existingPluNumbers);
            nextPlu = (maxPlu + 1).toString();
            console.log('Generated new PLU:', {
              maxExistingPlu: maxPlu,
              newPlu: nextPlu,
            });
          }

          currentPluNumbers.add(nextPlu);

          console.log('Updating row with match data:', {
            rowIndex: index,
            nextPlu,
            productName: data.productName,
            exactMatchExists: data.exactMatchExists,
            existingPlu: data.existingPlu,
            matchedAttributes: data.matchedAttributes,
            partialMatch: data.partialMatch,
            brandName: data.brandName,
          });

          setRows((prevRows) =>
            prevRows.map((r, idx) =>
              idx === index
                ? {
                    ...r,
                    pluNo: nextPlu,
                    articleName: data.productName || r.articleName,
                    exactMatchExists: data.exactMatchExists,
                    existingPlu: data.existingPlu,
                    matchedAttributes: data.matchedAttributes,
                    partialMatch: data.partialMatch,
                    brand: data.exactMatchExists ? data.brandName : r.brand,
                  }
                : r,
            ),
          );
        } catch (error: any) {
          console.error('Error processing row:', {
            rowIndex: index,
            articleNo: row.articleNo,
            error: error.response?.data || error.message,
          });
          if (error.response?.status === 409) {
            console.error('PLU conflict:', error.response.data.error);
          }
        }
      }

      setUsedPluNumbers(currentPluNumbers);
    }, DEBOUNCE_TIME);

    setPreviousRows(rows);
  };

  const handleSave = useCallback(
    async (publish: boolean) => {
      try {
        if (!importName.trim()) {
          setValidationDialog({
            open: true,
            errors: [{ row: 0, errors: ['Importnamn måste anges'] }],
          });
          return;
        }

        const validRows = rows
          .filter((row) => row.articleNo?.trim() && row.pluNo?.trim())
          .map((row) => {
            // Skapa en kopia av raden med alla ursprungliga fält
            const validRow: ImportRow = {
              ...row,
              attribute1: row.attribute1,
              attribute2: row.attribute2,
              attribute3: row.attribute3,
              attribute1Code: row.attribute1,
              attribute2Code: row.attribute2,
              attribute3Code: row.attribute3,
              ean13: row.ean13?.trim() || null,
              articleName: row.articleName?.trim() || null,
              supplierPrice: row.supplierPrice || null,
              weight: row.weight || null,
              price: row.price || null,
              priceOrig: row.priceOrig || null,
              brand: row.brand || null,
              activationDate: row.activationDate,
            };

            // Validera raden
            const errors = validateRow(validRow, rows.indexOf(row) + 1);
            if (errors.length > 0) {
              throw new Error(
                `Valideringsfel på rad ${rows.indexOf(row) + 1}: ${errors.join(
                  ', ',
                )}`,
              );
            }

            // Skapa payload för backend
            const payloadRow = {
              articleNo: row.articleNo,
              pluNo: row.pluNo,
              attribute1Code: row.attribute1,
              attribute2Code: row.attribute2,
              attribute3Code: row.attribute3,
              ean13: row.ean13?.trim() || null,
              articleName: row.articleName?.trim() || null,
              supplierPrice: row.supplierPrice || null,
              weight: row.weight || null,
              price: row.price || null,
              priceOrig: row.priceOrig || null,
              brand: row.brand || null,
              activationDate: row.activationDate
                ? new Date(row.activationDate).toISOString()
                : null,
            };

            return payloadRow;
          });

        console.log('Validerade rader att skicka:', validRows);

        const token = localStorage.getItem('token');
        const payload = {
          importName,
          rows: validRows,
        };

        // Om det är en publicering
        if (publish) {
          if (!id) {
            throw new Error('Import-ID saknas för publicering');
          }

          try {
            const response = await axios.post(
              `${config.API_URL}/product-imports/${id}/publish`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );

            setFeedback({
              open: true,
              message: 'Importen har publicerats',
              severity: 'success',
            });

            setIsDialogOpen(false);
            navigate('/product-import');
          } catch (error) {
            if (axios.isAxiosError(error)) {
              console.error('Fel vid publicering:', error);
              setFeedback({
                open: true,
                message:
                  error.response?.data?.error ||
                  'Ett fel uppstod vid publicering',
                severity: 'error',
              });
            }
            setIsDialogOpen(false);
          }
          return;
        }

        // Spara som utkast
        try {
          const url = id
            ? `${config.API_URL}/product-imports/${id}`
            : `${config.API_URL}/product-imports`;

          const method = id ? 'put' : 'post';

          const response = await axios[method](url, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (!id && response.data.id) {
            navigate(`/product-import/edit/${response.data.id}`, {
              replace: true,
            });
          }

          setFeedback({
            open: true,
            message: 'Importen har sparats',
            severity: 'success',
          });
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error('Fel vid sparande:', error);
            setFeedback({
              open: true,
              message:
                error.response?.data?.error || 'Ett fel uppstod vid sparande',
              severity: 'error',
            });
          }
        }
      } catch (error) {
        console.error('Fel vid sparande:', error);
        setValidationDialog({
          open: true,
          errors: [
            {
              row: 0,
              errors: [
                error instanceof Error
                  ? error.message
                  : 'Ett fel uppstod vid sparande',
              ],
            },
          ],
        });
      }
    },
    [importName, rows, id, navigate],
  );

  const columns = useMemo((): Column<ImportRow>[] => {
    const getCellClassName = (rowData: ImportRow) =>
      rowData.exactMatchExists
        ? 'exact-match-cell'
        : rowData.partialMatch
          ? 'partial-match-cell'
          : '';

    const baseColumnProps = {
      cellClassName: ({ rowData }: { rowData: ImportRow }) =>
        getCellClassName(rowData),
    };

    return [
      {
        ...keyColumn('articleNo', createTextColumn()),
        title: 'Artikelnummer',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('pluNo', createTextColumn()),
        title: 'PLU',
        disabled: true,
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn(
          'attribute1',
          selectColumn({
            choices: attributes.attribute1.map(
              (attr: string | AttributeOption) => ({
                label: typeof attr === 'string' ? attr : attr.code,
                value: typeof attr === 'string' ? attr : attr.code,
                toString: () => (typeof attr === 'string' ? attr : attr.code),
              }),
            ),
            disabled: false,
          }),
        ),
        title: 'Färg',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn(
          'attribute2',
          selectColumn({
            choices: attributes.attribute2.map(
              (attr: string | AttributeOption) => ({
                label: typeof attr === 'string' ? attr : attr.code,
                value: typeof attr === 'string' ? attr : attr.code,
                toString: () => (typeof attr === 'string' ? attr : attr.code),
              }),
            ),
            disabled: false,
          }),
        ),
        title: 'Storlek',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn(
          'attribute3',
          selectColumn({
            choices: attributes.attribute3.map(
              (attr: string | AttributeOption) => ({
                label: typeof attr === 'string' ? attr : attr.code,
                value: typeof attr === 'string' ? attr : attr.code,
                toString: () => (typeof attr === 'string' ? attr : attr.code),
              }),
            ),
            disabled: false,
          }),
        ),
        title: 'Längd',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('ean13', createTextColumn()),
        title: 'EAN13',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('articleName', createTextColumn()),
        title: 'Artikelnamn',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('supplierPrice', createTextColumn()),
        title: 'Inpris',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('priceOrig', createTextColumn()),
        title: 'Ord. pris',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('price', createTextColumn()),
        title: 'Pris',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('weight', createTextColumn()),
        title: 'Vikt (g)',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn(
          'brand',
          selectColumn({
            choices: attributes.brands.map((brand: string) => ({
              label: brand,
              value: brand,
              toString: () => brand,
            })),
            disabled: false,
          }),
        ),
        title: 'Varumärke',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('activationDate', dateColumn),
        title: 'Aktiveringsdatum',
        cellClassName: baseColumnProps.cellClassName,
      },
    ] as Column<ImportRow>[];
  }, [attributes]);

  const gridProps: DataSheetGridProps<ImportRow> = {
    value: rows,
    onChange: handleRowsChange,
    columns: columns,
    createRow: createEmptyRow,
    duplicateRow: ({ rowData }) => ({
      ...rowData,
      pluNo: '',
    }),
    rowKey: generateRowKey,
    rowClassName: ({ rowData }) =>
      rowData.exactMatchExists
        ? 'exact-match-row'
        : rowData.partialMatch
          ? 'partial-match-row'
          : '',
  };

  return (
    <Container maxWidth={false}>
      <Typography variant="h4" gutterBottom>
        {id
          ? isReadOnly
            ? 'Visa import'
            : 'Redigera import'
          : 'Ny produktimport'}
      </Typography>

      <Box sx={{ mb: 2 }}>
        <TextField
          label="Importnamn"
          value={importName}
          onChange={(e) => setImportName(e.target.value)}
          fullWidth
          disabled={isReadOnly}
          required
          error={!importName.trim()}
          helperText={!importName.trim() ? 'Importnamn måste anges' : ''}
        />
      </Box>

      {feedback.open && (
        <Box sx={{ mb: 2 }}>
          <Typography
            color={feedback.severity === 'error' ? 'error' : 'success'}
            sx={{ whiteSpace: 'pre-line' }}
          >
            {feedback.message}
          </Typography>
        </Box>
      )}

      <Paper sx={{ p: 2, height: '70vh' }}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography>Laddar...</Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
              >
                <Box
                  component="span"
                  sx={{
                    width: 16,
                    height: 16,
                    bgcolor: '#fff3e0',
                    border: '1px solid #ffb74d',
                  }}
                />
                Exakt matchning med befintlig produkt
              </Typography>
            </Box>

            <Box
              sx={{
                '& .exact-match-cell': {
                  backgroundColor: '#fff3e0 !important',
                  borderColor: '#ffb74d !important',
                },
                '& .partial-match-cell': {
                  backgroundColor: '#f5f5f5 !important',
                  borderColor: '#bdbdbd !important',
                },
              }}
            >
              <DataSheetGrid<ImportRow> {...gridProps} ref={gridRef} />
            </Box>
          </>
        )}
      </Paper>

      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button variant="outlined" onClick={() => navigate('/product-import')}>
          {isReadOnly ? 'Stäng' : 'Avbryt'}
        </Button>
        {!isReadOnly && (
          <>
            <Button variant="contained" onClick={() => handleSave(false)}>
              Spara som utkast
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsDialogOpen(true)}
            >
              Publicera
            </Button>
          </>
        )}
      </Box>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Bekräfta publicering</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Är du säker på att du vill publicera denna import?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Avbryt</Button>
          <Button
            onClick={() => handleSave(true)}
            variant="contained"
            color="primary"
          >
            Publicera
          </Button>
        </DialogActions>
      </Dialog>

      {/* Validation Dialog */}
      <Dialog
        open={validationDialog.open}
        onClose={() => setValidationDialog({ open: false, errors: [] })}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Valideringsfel</DialogTitle>
        <DialogContent>
          {groupValidationErrors(validationDialog.errors).map(
            (error, index) => (
              <Typography key={index} color="error" sx={{ mb: 1 }}>
                {error.message}
                {error.rows[0] !== 0 && ` (Rad: ${error.rows.join(', ')})`}
              </Typography>
            ),
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setValidationDialog({ open: false, errors: [] })}
          >
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProductImportEdit;
