// selectColumn.ts
import { Column } from 'react-datasheet-grid';
import SelectComponent from './selectComponent';

interface Choice {
  label: string;
  value: string;
}

interface SelectOptions {
  choices: Choice[];
  disabled?: boolean;
}

export const selectColumn = (
  options: SelectOptions,
): Column<string | null, SelectOptions> => ({
  component: SelectComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: ({ rowData }) =>
    options.choices.find((choice) => choice.value === rowData)?.value ?? null,
  pasteValue: ({ value }) => {
    if (!value) {
      return null;
    }

    // Trimma bort whitespace och radbrytningar
    const cleanValue = value.trim();

    // Först, försök hitta en exakt matchning (case-insensitive)
    const exactMatch = options.choices.find(
      (choice) =>
        choice.value.toLowerCase() === cleanValue.toLowerCase() ||
        choice.label.toLowerCase() === cleanValue.toLowerCase(),
    );

    if (exactMatch) {
      return exactMatch.value;
    }

    // Om ingen exakt matchning hittas, försök med partiell matchning
    const partialMatch = options.choices.find(
      (choice) =>
        choice.value.toLowerCase().includes(cleanValue.toLowerCase()) ||
        choice.label.toLowerCase().includes(cleanValue.toLowerCase()),
    );

    return partialMatch?.value ?? null;
  },
});
