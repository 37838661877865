import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: '2.5rem',
            fontWeight: 600,
            textAlign: 'center',
            mb: 4,
          }}
        >
          Välkommen till MiRAKEL
        </Typography>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            color: 'text.secondary',
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          Ditt centrala verktyg för att hantera produkter, användare och
          företagsinformation på ett effektivt sätt.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <AssignmentIcon
                  sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
                />
                <Typography variant="h5">Produkthantering</Typography>
              </Box>
              <Typography variant="body1" color="text.secondary">
                Hantera produktbeskrivningar och bilder för alla artiklar i
                systemet. Använd AI-assistans för att generera
                produktbeskrivningar.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <MonetizationOnIcon
                  sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
                />
                <Typography variant="h5">Prishantering</Typography>
              </Box>
              <Typography variant="body1" color="text.secondary">
                Uppdatera och hantera priser för alla produkter. Se både
                ordinarie priser och kampanjpriser i realtid.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <GroupIcon
                  sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
                />
                <Typography variant="h5">Användarhantering</Typography>
              </Box>
              <Typography variant="body1" color="text.secondary">
                Administrera användarkonton och behörigheter för att säkerställa
                rätt åtkomst till systemets olika funktioner.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <SmartToyIcon
                  sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
                />
                <Typography variant="h5">AI-Assistans</Typography>
              </Box>
              <Typography variant="body1" color="text.secondary">
                Använd vår AI-drivna assistent för att automatisera och
                förbättra produktbeskrivningar och innehåll.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
