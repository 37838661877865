// ProductList.tsx
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  Chip,
  IconButton,
  CircularProgress,
  Box,
  ImageList,
  Grid,
} from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SmartImage from '../components/SmartImage';
import UploadMediaModal from '../components/UploadMediaModal';
import config from '../config';
import FilterListIcon from '@mui/icons-material/FilterList';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import ImageIcon from '@mui/icons-material/Image';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import InventoryIcon from '@mui/icons-material/Inventory';

interface Product {
  productId: number;
  productNumber: string;
  productName: string;
  hasCategories: number;
  hasValidDescription: number;
  totalColors: number;
  colorsWithImages: number;
  longDescription?: string;
  brandName?: string;
  stockByColor?: {
    [key: string]: StockInfo;
  };
}

interface Category {
  categoryId: number;
  categoryName: string;
  parentCategoryId: number | null;
  children?: Category[];
}

interface CategoryOption {
  categoryId: number;
  categoryName: string;
  fullPath: string;
  parentCategoryId: number | null;
  depth: number;
}

interface SKU {
  skuId: number;
  priceLookupUnitNumber: string;
  attribute1Code: string;
  attribute1Number: string;
}

interface UploadData {
  articleNumber: string;
  colorCode: string;
  attribute1Number: string;
  images: File[];
  transparentIndices: number[];
}

interface StockInfo {
  attribute1Code: string;
  attribute1Number: string;
  stockBalance: number;
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(0); // zero-based index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [longDescriptionFilter, setLongDescriptionFilter] = useState(false);
  const [noCategoryFilter, setNoCategoryFilter] = useState(false);
  const [noImageFilter, setNoImageFilter] = useState(false);
  const [partialImageFilter, setPartialImageFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productDescription, setProductDescription] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [categoryMap, setCategoryMap] = useState<{
    [key: number]: CategoryOption;
  }>({});
  const [parentMap, setParentMap] = useState<{ [key: number]: number | null }>(
    {},
  );
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryOption[]
  >([]);
  const [productCategoryIds, setProductCategoryIds] = useState<number[]>([]);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const [isGenerating, setIsGenerating] = useState(false);
  const [productMedia, setProductMedia] = useState<{
    [attribute1Number: string]: string[];
  }>({});
  const [skus, setSkus] = useState<SKU[]>([]);

  // Lägg till state för tillgängliga färger
  const [availableColors, setAvailableColors] = useState<string[]>([]);

  // State för Upload Media Modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedSku, setSelectedSku] = useState<SKU | null>(null);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  // Lägg till ny state-variabel
  const [noBrandInDescFilter, setNoBrandInDescFilter] = useState(false);

  // Lägg till state för expand/collapse
  const [expanded, setExpanded] = useState(false);

  // Lägg till nya state-variabler
  const [noStockFilter, setNoStockFilter] = useState(false);
  const [partialStockFilter, setPartialStockFilter] = useState(false);

  // Lägg till ny state-variabel
  const [isSaving, setIsSaving] = useState(false);

  // Hanterare för expand/collapse
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    search,
    longDescriptionFilter,
    noCategoryFilter,
    noImageFilter,
    partialImageFilter,
    noBrandInDescFilter,
    noStockFilter,
    partialStockFilter,
  ]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${config.API_URL}/products`, {
        params: {
          search,
          page: page + 1, // backend pages start from 1
          pageSize: rowsPerPage,
          longDescriptionFilter,
          noCategoryFilter,
          noImageFilter,
          partialImageFilter,
          noBrandInDescFilter,
          noStockFilter,
          partialStockFilter,
        },
      });
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/categories`);
      const categoryData = response.data;
      const flatCategories = flattenCategories(categoryData);
      setCategoryOptions(flatCategories);

      // Skapa maps för enkel åtkomst
      const cMap: { [key: number]: CategoryOption } = {};
      const pMap: { [key: number]: number | null } = {};
      flatCategories.forEach((cat) => {
        cMap[cat.categoryId] = cat;
        pMap[cat.categoryId] = cat.parentCategoryId;
      });
      setCategoryMap(cMap);
      setParentMap(pMap);
    } catch (error) {
      console.error(error);
    }
  };

  const flattenCategories = (
    categories: Category[],
    parentPath = '',
    depth = 0,
  ): CategoryOption[] => {
    let flatList: CategoryOption[] = [];

    categories.forEach((category) => {
      const currentPath = parentPath
        ? `${parentPath} > ${category.categoryName}`
        : category.categoryName;

      flatList.push({
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        fullPath: currentPath,
        parentCategoryId: category.parentCategoryId,
        depth: depth,
      });

      if (category.children && category.children.length > 0) {
        flatList = flatList.concat(
          flattenCategories(category.children, currentPath, depth + 1),
        );
      }
    });

    return flatList;
  };

  useEffect(() => {
    if (productCategoryIds && categoryOptions.length > 0) {
      // Mappa produktkategorier till categoryOptions
      const selectedCats = categoryOptions.filter((cat) =>
        productCategoryIds.includes(cat.categoryId),
      );

      // Inkludera föräldrakategorier
      const allSelectedCategoriesMap = new Map<number, CategoryOption>();
      selectedCats.forEach((cat) => {
        allSelectedCategoriesMap.set(cat.categoryId, cat);
        const ancestors = getAncestors(cat.categoryId);
        ancestors.forEach((ancestor) => {
          if (!allSelectedCategoriesMap.has(ancestor.categoryId)) {
            allSelectedCategoriesMap.set(ancestor.categoryId, ancestor);
          }
        });
      });

      const allSelectedCategories = Array.from(
        allSelectedCategoriesMap.values(),
      );

      // Sortera kategorier baserat på djup
      allSelectedCategories.sort((a, b) => a.depth - b.depth);

      setSelectedCategories(allSelectedCategories);
    } else {
      setSelectedCategories([]);
    }
  }, [productCategoryIds, categoryOptions]);

  const getAncestors = (categoryId: number): CategoryOption[] => {
    const ancestors: CategoryOption[] = [];
    let currentCategoryId = categoryId;
    while (
      parentMap[currentCategoryId] !== null &&
      parentMap[currentCategoryId] !== undefined
    ) {
      const parentId = parentMap[currentCategoryId];
      if (parentId !== null && categoryMap[parentId]) {
        ancestors.unshift(categoryMap[parentId]);
        currentCategoryId = parentId;
      } else {
        break;
      }
    }
    return ancestors;
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleLongDescriptionFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLongDescriptionFilter(e.target.checked);
    setPage(0);
  };

  const handleNoCategoryFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoCategoryFilter(e.target.checked);
    setPage(0);
  };

  const handleNoImageFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoImageFilter(e.target.checked);
    setPage(0);
  };

  const handlePartialImageFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPartialImageFilter(e.target.checked);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProductClick = async (product: Product) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/products/${product.productId}`,
      );
      const productData = response.data;
      setSelectedProduct(productData);

      // Konvertera HTML till DraftJS ContentState
      const html = productData.longDescription || '';
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      } else {
        setEditorState(EditorState.createEmpty());
      }

      // Initiera `productDescription` med befintlig HTML
      setProductDescription(html);

      // Hämta produktkategorier
      const categoryResponse = await axios.get(
        `${config.API_URL}/products/${product.productId}/categories`,
      );
      const productCategories = categoryResponse.data as number[];
      setProductCategoryIds(productCategories);

      // Hämta produkt-SKUs
      const skuResponse = await axios.get(
        `${config.API_URL}/products/${product.productId}/skus`,
      );
      const skuData = skuResponse.data as SKU[];
      setSkus(skuData);

      // Hämta produktmedia
      const mediaResponse = await axios.get(
        `${config.API_URL}/products/${product.productId}/media`,
      );
      setProductMedia(mediaResponse.data);

      // Extrahera unika färger från SKUs
      const colors = Array.from(new Set(skuData.map((s) => s.attribute1Code)));
      setAvailableColors(colors);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
    const content = draftToHtml(convertToRaw(state.getCurrentContent()));
    setProductDescription(content);
  };

  const handleSaveChanges = async () => {
    if (!selectedProduct) return;

    setIsSaving(true);
    try {
      await axios.put(
        `${config.API_URL}/products/${selectedProduct.productId}`,
        {
          longDescription: productDescription,
          categoriesToReplaceExisting: selectedCategories.map((cat) => ({
            categoryId: cat.categoryId,
          })),
        },
      );
      alert('Produktuppgifter har sparats.');
      handleCloseDialog();
      fetchProducts();
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Ett fel inträffade vid sparande av ändringar.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseDialog = () => {
    setSelectedProduct(null);
    setProductDescription('');
    setSelectedCategories([]);
    setProductCategoryIds([]);
    setEditorState(EditorState.createEmpty());
    setProductMedia({});
    setSkus([]);
    setAvailableColors([]);
  };

  const handleCategoryChange = (event: any, newValue: CategoryOption[]) => {
    // Automatisk lägga till föräldrakategorier
    const allSelectedCategoriesMap = new Map<number, CategoryOption>();
    newValue.forEach((cat) => {
      allSelectedCategoriesMap.set(cat.categoryId, cat);
      const ancestors = getAncestors(cat.categoryId);
      ancestors.forEach((ancestor) => {
        if (!allSelectedCategoriesMap.has(ancestor.categoryId)) {
          allSelectedCategoriesMap.set(ancestor.categoryId, ancestor);
        }
      });
    });

    // Konvertera map till array
    const allSelectedCategories = Array.from(allSelectedCategoriesMap.values());

    // Sortera kategorier baserat på djup (lägst djup först)
    allSelectedCategories.sort((a, b) => a.depth - b.depth);

    setSelectedCategories(allSelectedCategories);
  };

  // Funktion för att generera beskrivning med AI
  const handleGenerateDescription = async () => {
    if (!selectedProduct) return;

    setIsGenerating(true);
    try {
      const content = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );

      const response = await axios.post(
        `${config.API_URL}/products/${selectedProduct.productId}/generate-description`,
        {
          currentDescription: content,
        },
      );

      let generatedDescription = response.data.generatedDescription;

      // Formatera texten med HTML-paragrafer
      generatedDescription = generatedDescription
        .split('\n\n')
        .filter((paragraph: string) => paragraph.trim() !== '')
        .map((paragraph: string) => `<p>${paragraph.trim()}</p>`)
        .join('');

      const contentBlock = htmlToDraft(generatedDescription);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap,
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setProductDescription(generatedDescription);
      }
    } catch (error) {
      console.error('Error generating description:', error);
      alert('Ett fel inträffade vid generering av produktbeskrivning.');
    } finally {
      setIsGenerating(false);
    }
  };

  // Hantera förhandsgranskning (processa bilder utan att st��nga modal)
  const handlePreview = async (data: UploadData) => {
    try {
      // Skicka data till backend
      const formData = new FormData();
      formData.append('articleNumber', data.articleNumber);
      formData.append('colorCode', data.colorCode);
      formData.append('attribute1Number', data.attribute1Number);
      formData.append(
        'transparentIndices',
        JSON.stringify(data.transparentIndices),
      );
      data.images.forEach((file) => {
        formData.append('images', file, file.name);
      });

      const response = await axios.post(
        `${config.API_URL}/upload/preview`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      // Lägg till API_URL till varje bild-URL som kommer tillbaka
      const imageUrls = response.data.imageUrls.map(
        (url: string) => `${config.API_URL}/${url}`,
      );

      setUploadedImages(imageUrls);
    } catch (error) {
      console.error(error);
      alert('Ett fel inträffade vid bearbetning av bilder.');
    }
  };

  const handleUploadSubmit = async (data: UploadData) => {
    try {
      // Skicka data till backend för att spara bilder
      const response = await axios.post(`${config.API_URL}/upload/save`, {
        articleNumber: data.articleNumber,
        colorCode: data.colorCode,
        attribute1Number: data.attribute1Number,
        imageUrls: uploadedImages,
      });

      // Uppdatera produktmedia i state
      if (selectedProduct) {
        setProductMedia((prevMedia) => ({
          ...prevMedia,
          [data.attribute1Number]: uploadedImages,
        }));
      }

      alert('Bilder har sparats.');
      handleCloseUploadModal();
    } catch (error) {
      console.error(error);
      alert('Ett fel inträffade vid sparande av bilder.');
      throw error; // Kasta om felet så att UploadMediaModal kan hantera isSaving
    }
  };

  const handleOpenUploadModal = (sku: SKU) => {
    setSelectedSku(sku);
    setIsUploadModalOpen(true);
    setUploadedImages([]); // Återställ uppladdade bilder
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    setSelectedSku(null);
    setUploadedImages([]);
  };

  // Lägg till ny hanterare för filtret
  const handleNoBrandInDescFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoBrandInDescFilter(e.target.checked);
    setPage(0);
  };

  // Lägg till nya hanterare för filterändringar
  const handleNoStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoStockFilter(e.target.checked);
    setPage(0);
  };

  const handlePartialStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPartialStockFilter(e.target.checked);
    setPage(0);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Produkthantering
      </Typography>
      <TextField
        label="Sök"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        sx={{ mb: 2, width: '100%' }}
      />

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleExpandClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterListIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Produktfilter</Typography>
          </Box>
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="visa filter"
            sx={{ ml: 'auto' }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Beskrivningsfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <DescriptionIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Beskrivning</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={longDescriptionFilter}
                        onChange={handleLongDescriptionFilterChange}
                      />
                    }
                    label="Kort produktbeskrivning"
                  />
                </Paper>
              </Grid>

              {/* Kategorifilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CategoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Kategorier</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noCategoryFilter}
                        onChange={handleNoCategoryFilterChange}
                      />
                    }
                    label="Saknar kategori"
                  />
                </Paper>
              </Grid>

              {/* Bildfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <ImageIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Bilder</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noImageFilter}
                        onChange={handleNoImageFilterChange}
                      />
                    }
                    label="Saknar bilder"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partialImageFilter}
                        onChange={handlePartialImageFilterChange}
                      />
                    }
                    label="Ofullständiga bilder"
                  />
                </Paper>
              </Grid>

              {/* Varumärkesfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <BrandingWatermarkIcon
                      sx={{ mr: 1, color: 'primary.main' }}
                    />
                    <Typography variant="subtitle1">Varumärke</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noBrandInDescFilter}
                        onChange={handleNoBrandInDescFilterChange}
                      />
                    }
                    label="Saknar varumärke i beskrivning"
                  />
                </Paper>
              </Grid>

              {/* Lagersaldofilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <InventoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Lagersaldo</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noStockFilter}
                        onChange={handleNoStockFilterChange}
                      />
                    }
                    label="Saknar lagersaldo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partialStockFilter}
                        onChange={handlePartialStockFilterChange}
                      />
                    }
                    label="Ofullständigt lagersaldo"
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Paper>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(18, 18, 18, 0.7)',
              backdropFilter: 'blur(4px)',
              zIndex: 1,
            }}
          >
            <CircularProgress sx={{ color: '#BB86FC' }} />
          </Box>
        )}
        <Table stickyHeader aria-label="Produktlista">
          <TableHead>
            <TableRow>
              <TableCell>Artikelnummer</TableCell>
              <TableCell>Produktnamn</TableCell>
              <TableCell>Har kategorier</TableCell>
              <TableCell>Giltig produktbeskrivning</TableCell>
              <TableCell>Bildstatus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow
                key={`${product.productId}-${index}`}
                hover
                onClick={() => handleProductClick(product)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{product.productNumber}</TableCell>
                <TableCell>{product.productName}</TableCell>
                <TableCell>{product.hasCategories ? 'Ja' : 'Nej'}</TableCell>
                <TableCell>
                  {product.hasValidDescription ? 'Ja' : 'Nej'}
                </TableCell>
                <TableCell>
                  {product.colorsWithImages === 0
                    ? 'Inga bilder'
                    : product.colorsWithImages === product.totalColors
                      ? 'Alla färger har bilder'
                      : 'Ofullständiga bilder'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        component="div"
        count={totalPages * rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog för produktdetaljer */}
      <Dialog
        open={!!selectedProduct}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            height: '90vh',
            maxHeight: '90vh',
            position: 'relative',
          },
        }}
      >
        {isSaving && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(18, 18, 18, 0.9)',
              backdropFilter: 'blur(4px)',
              zIndex: 9999,
              borderRadius: '4px',
            }}
          >
            <CircularProgress sx={{ color: '#BB86FC' }} />
          </Box>
        )}
        <DialogTitle>
          {selectedProduct?.productNumber} - {selectedProduct?.productName}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            overflowY: 'auto', // Aktiverar vertikal scroll
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.2)',
              borderRadius: '4px',
            },
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Produktbeskrivning:
          </Typography>

          {/* Container för editor och AI-knapp */}
          <Box sx={{ position: 'relative' }}>
            {/* AI Generation button placerad längst ner till höger inne i editorn */}
            <span>
              <IconButton
                onClick={handleGenerateDescription}
                color="secondary"
                sx={{
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                  zIndex: 1,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    transform: 'scale(1.3)',
                  },
                }}
                disabled={isGenerating}
              >
                {isGenerating ? (
                  <CircularProgress size={24} />
                ) : (
                  <SmartToyIcon />
                )}
              </IconButton>
            </span>
            {/* Editor */}
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={handleEditorChange}
              editorStyle={{
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                minHeight: '200px',
                maxHeight: '200px',
                padding: '10px 50px 40px 14px',
              }}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'list',
                  'link',
                  'embedded',
                  'emoji',
                  'remove',
                  'history',
                ],
              }}
            />
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Varumärke:
            </Box>{' '}
            {selectedProduct?.brandName}
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ marginTop: '20px' }}
          >
            Kategorier:
          </Typography>
          <Autocomplete
            multiple
            options={categoryOptions}
            getOptionLabel={(option) => option.fullPath}
            value={selectedCategories}
            onChange={handleCategoryChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...restTagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={option.categoryId}
                    label={option.fullPath}
                    {...restTagProps}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Kategorier"
                placeholder="Välj kategorier"
              />
            )}
            noOptionsText="Inga kategorier tillgängliga"
          />

          {/* Visa bilder baserat på färg */}
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ marginTop: '20px' }}
          >
            Bilder:
          </Typography>
          {skus.map((sku) => (
            <Box key={sku.attribute1Number} sx={{ marginBottom: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  marginBottom: 1,
                }}
              >
                <Typography variant="subtitle2">
                  Färg: {sku.attribute1Code}
                </Typography>
                {selectedProduct?.stockByColor && (
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        selectedProduct.stockByColor[sku.attribute1Number]
                          ?.stockBalance > 0
                          ? 'success.main'
                          : 'error.main',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                    }}
                  >
                    <InventoryIcon sx={{ fontSize: 16 }} />
                    {selectedProduct.stockByColor[sku.attribute1Number]
                      ?.stockBalance > 0
                      ? `Lagersaldo: ${selectedProduct.stockByColor[sku.attribute1Number].stockBalance} st`
                      : 'Ej i lager'}
                  </Typography>
                )}
              </Box>

              {productMedia[sku.attribute1Number] &&
              productMedia[sku.attribute1Number].length > 0 ? (
                <ImageList
                  cols={8}
                  gap={4}
                  sx={{ width: '100%', height: 'auto' }}
                >
                  {productMedia[sku.attribute1Number].map(
                    (imageName, index) => (
                      <Box
                        key={index}
                        sx={{
                          height: 100,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 0,
                          backgroundColor: 'lightgray',
                          borderRadius: '8px',
                          overflow: 'hidden',
                        }}
                      >
                        <SmartImage
                          baseUrl="https://media.viskanassets.com/v2/enghssportprod/original/"
                          imageName={imageName}
                          alt={`Bild ${index + 1}`}
                          loading="lazy"
                          style={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Box>
                    ),
                  )}
                </ImageList>
              ) : (
                <Box
                  sx={{
                    height: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'lightgray',
                    borderRadius: '8px',
                    flexDirection: 'column',
                    cursor: 'pointer', // Gör den klickbar
                  }}
                  onClick={() => handleOpenUploadModal(sku)}
                >
                  <AddCircleOutlineIcon
                    sx={{
                      fontSize: 50,
                      color: 'gray',
                      marginBottom: '4px', // Utrymme mellan ikon och text
                    }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Ingen media kopplad. Lägg till media
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Avbryt</Button>
          <Button
            onClick={handleSaveChanges}
            color="primary"
            variant="contained"
            disabled={isSaving}
          >
            {isSaving ? 'Sparar...' : 'Spara'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Media Modal */}
      {selectedSku && (
        <UploadMediaModal
          open={isUploadModalOpen}
          onClose={handleCloseUploadModal}
          onSubmit={handleUploadSubmit}
          uploadedImages={uploadedImages}
          handlePreview={handlePreview}
          productNumber={selectedProduct?.productNumber || ''}
          availableColors={availableColors}
          initialColorCode={selectedSku.attribute1Code}
          attribute1Number={selectedSku.attribute1Number} // Passa attribute1Number
        />
      )}
    </Container>
  );
};

export default ProductList;
