import React, { useState, useEffect } from 'react';
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import config from '../config';

// Definiera gränssnitt för produkter och SKU-priser
interface Product {
  productId: number;
  productNumber: string;
  productName: string;
}

interface SKUPrice {
  skuId: number;
  priceLookupUnitNumber: string;
  attribute1Code: string;
  unitPrice: number; // Aktuellt försäljningspris
  unitPriceOriginal: number; // Ordinarie pris
  taxRate: number;
}

// Huvudkomponenten för prisändring
const PriceChangeModule: React.FC = () => {
  // States för produkter, sökning, vald produkt, paginering och dialog
  const [products, setProducts] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<
    (Product & { skus: SKUPrice[] }) | null
  >(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Ny state för att hantera prisinmatningar som strängar
  const [priceInputs, setPriceInputs] = useState<{
    [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
  }>({});

  const [isLoading, setIsLoading] = useState(false);

  // Funktion för att hämta produkter från API
  const fetchProducts = async (
    search: string,
    page: number,
    pageSize: number,
  ) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${config.API_URL}/products?search=${encodeURIComponent(
          search,
        )}&page=${page + 1}&pageSize=${pageSize}`,
      );

      if (!response.ok) {
        console.error(
          'Server responded with an error:',
          response.status,
          response.statusText,
        );
        return;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Funktion för att hämta SKUs för en specifik produkt från API
  const fetchProductSKUs = async (productId: number) => {
    try {
      const response = await fetch(
        `${config.API_URL}/products/${productId}/skus`,
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          'Server responded with an error:',
          response.status,
          response.statusText,
          errorText,
        );
        return;
      }

      const data: SKUPrice[] = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching SKUs:', error);
    }
  };

  // Ladda produkter när sökterm eller sida ändras
  useEffect(() => {
    const loadProducts = async () => {
      const data = await fetchProducts(searchTerm, page, rowsPerPage);
      if (data) {
        setProducts(data.products);
        setTotalPages(data.totalPages);
      }
    };
    loadProducts();
  }, [searchTerm, page, rowsPerPage]);

  // Initiera priceInputs när en produkt väljs och SKUs hämtas
  useEffect(() => {
    if (selectedProduct) {
      const initialInputs: {
        [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
      } = {};
      selectedProduct.skus.forEach((sku) => {
        initialInputs[sku.skuId] = {
          unitPrice: sku.unitPrice.toString().replace('.', ','),
          unitPriceOriginal: sku.unitPriceOriginal.toString().replace('.', ','),
        };
      });
      setPriceInputs(initialInputs);
    } else {
      setPriceInputs({});
    }
  }, [selectedProduct]);

  // Hantera klick på en produkt för att hämta och visa SKUs
  const handleProductClick = async (product: Product) => {
    const skuData = await fetchProductSKUs(product.productId);
    if (skuData) {
      setSelectedProduct({ ...product, skus: skuData });
      setIsDialogOpen(true);
    }
  };

  // Hantera sökinput
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
    setSelectedProduct(null);
  };

  // Hantera stängning av dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedProduct(null);
    setPriceInputs({});
  };

  // Hantera prisändring genom att uppdatera priceInputs state
  const handlePriceChange = (
    skuId: number,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => {
    setPriceInputs((prev) => ({
      ...prev,
      [skuId]: {
        ...prev[skuId],
        [priceType]: value,
      },
    }));
  };

  // Hantera sparande av priser genom att konvertera strängar till nummer och skicka till API
  const handleSavePrices = async () => {
    if (!selectedProduct) return;

    // Konvertera strängar till nummer och hantera decimaler
    const updatedSkus = selectedProduct.skus.map((sku) => {
      const input = priceInputs[sku.skuId];
      return {
        ...sku,
        unitPriceOriginal:
          parseFloat(input?.unitPriceOriginal.replace(',', '.')) ||
          sku.unitPriceOriginal,
        unitPrice:
          parseFloat(input?.unitPrice.replace(',', '.')) || sku.unitPrice,
      };
    });

    try {
      const response = await fetch(
        `${config.API_URL}/products/${selectedProduct.productId}/update-prices`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ skus: updatedSkus }),
        },
      );

      const data = await response.json();

      if (response.ok) {
        // Successmeddelande
        alert('Priser uppdaterade');
        handleCloseDialog();
        // Eventuellt uppdatera produktlistan om nödvändigt
      } else {
        // Felmeddelande från backend
        alert(`Fel vid uppdatering av priser: ${data.error}`);
      }
    } catch (error) {
      console.error('Error updating prices:', error);
      alert('Ett fel inträffade vid uppdatering av priser');
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Prishantering
      </Typography>

      <TextField
        label="Sök artikelnummer eller produktnamn..."
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        margin="normal"
      />

      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(18, 18, 18, 0.7)',
              backdropFilter: 'blur(4px)',
              zIndex: 1,
            }}
          >
            <CircularProgress sx={{ color: '#BB86FC' }} />
          </Box>
        )}
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Artikelnummer</TableCell>
              <TableCell>Produktnamn</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow
                key={product.productId}
                hover
                style={{ cursor: 'pointer' }}
                onClick={() => handleProductClick(product)}
              >
                <TableCell>{product.productNumber}</TableCell>
                <TableCell>{product.productName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={totalPages * rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog för vald produkt */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {selectedProduct?.productNumber} - {selectedProduct?.productName}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Färg</TableCell>
                  <TableCell>Ordinarie Pris</TableCell>
                  <TableCell>Försäljningspris</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProduct?.skus.map((sku) => (
                  <TableRow key={sku.skuId}>
                    <TableCell>{sku.attribute1Code}</TableCell>
                    <TableCell>
                      <TextField
                        value={priceInputs[sku.skuId]?.unitPriceOriginal || ''}
                        variant="outlined"
                        size="small"
                        label="SEK"
                        onChange={(e) =>
                          handlePriceChange(
                            sku.skuId,
                            'unitPriceOriginal',
                            e.target.value,
                          )
                        }
                        // Validera att endast tillåtna tecken används
                        inputProps={{
                          inputMode: 'decimal',
                          pattern: '^[0-9]*[.,]?[0-9]*$',
                        }}
                      />{' '}
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={priceInputs[sku.skuId]?.unitPrice || ''}
                        variant="outlined"
                        size="small"
                        label="SEK"
                        onChange={(e) =>
                          handlePriceChange(
                            sku.skuId,
                            'unitPrice',
                            e.target.value,
                          )
                        }
                        inputProps={{
                          inputMode: 'decimal',
                          pattern: '^[0-9]*[.,]?[0-9]*$',
                        }}
                      />{' '}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Stäng
          </Button>
          <Button
            onClick={handleSavePrices}
            color="primary"
            variant="contained"
          >
            Spara
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PriceChangeModule;
